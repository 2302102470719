// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maintenanceBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh; }
  .maintenanceBox img {
    width: 400px;
    height: auto; }
  .maintenanceBox .title {
    margin-top: 2rem;
    font-size: 22px; }
  .maintenanceBox .helperTextBox {
    text-align: center;
    margin-top: 1rem; }
    .maintenanceBox .helperTextBox p {
      margin: 0;
      font-size: 14px;
      color: grey; }
`, "",{"version":3,"sources":["webpack://./src/views/Maintenance/maintenance.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,aAAa,EAAA;EALjB;IAQQ,YAAY;IACZ,YAAY,EAAA;EATpB;IAaQ,gBAAgB;IAChB,eAAe,EAAA;EAdvB;IAkBQ,kBAAkB;IAClB,gBAAgB,EAAA;IAnBxB;MAsBY,SAAS;MACT,eAAe;MACf,WAAW,EAAA","sourcesContent":[".maintenanceBox {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    height: 100vh;\n\n    img {\n        width: 400px;\n        height: auto;\n    }\n\n    .title {\n        margin-top: 2rem;\n        font-size: 22px;\n    }\n\n    .helperTextBox {\n        text-align: center;\n        margin-top: 1rem;\n\n        p {\n            margin: 0;\n            font-size: 14px;\n            color: grey;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
