// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whatsapp-fab {
  position: fixed;
  inset: auto 0 0 auto;
  margin: 20px;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px 0px;
  border-radius: 50%;
  background-color: #25d366;
  cursor: pointer; }
  .whatsapp-fab svg {
    font-size: 38px;
    color: #fff;
    margin: 12px 1rem; }
    @media (max-width: 767px) {
      .whatsapp-fab svg {
        font-size: 28px;
        margin: 8px 9px 5px 10px; } }
`, "",{"version":3,"sources":["webpack://./src/components/WhatsAppFabButton/WhatsAppFabButton.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,oBAAoB;EACpB,YAAY;EACZ,aAAa;EACb,+CAA+C;EAC/C,kBAAkB;EAClB,yBAAyB;EACzB,eAAe,EAAA;EARnB;IAWQ,eAAe;IACf,WAAW;IACX,iBAAiB,EAAA;IAEjB;MAfR;QAgBY,eAAe;QACf,wBAAwB,EAAA,EAE/B","sourcesContent":[".whatsapp-fab {\n    position: fixed;\n    inset: auto 0 0 auto;\n    margin: 20px;\n    z-index: 9999;\n    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px 0px;\n    border-radius: 50%;\n    background-color: #25d366;\n    cursor: pointer;\n\n    svg {\n        font-size: 38px;\n        color: #fff;\n        margin: 12px 1rem;\n\n        @media (max-width: 767px) {\n            font-size: 28px;\n            margin: 8px 9px 5px 10px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
