export { default as Blogs } from './blogs';
export { default as ProductSlider } from './four-view-product';
export { default as HowItWorks } from './how-it-works';
export { default as SocialUpdates } from './social-updates';
export { default as WhatWeOffer } from './what-we-offer';
export { default as NavBar } from './nav-bar';
export { default as SelectVehicle } from './select-vehicle';
export { default as SelectDesktopVehicle } from './select-desktop-vehicle';
export { default as TestimonialSlider } from './testimonial-slider';
export { default as Footer } from './footer';
export { default as Banner } from './banner/Banner';
